import React, { useState } from "react";
import ReactDOM from "react-dom";

import { Navigate, useNavigate } from "react-router-dom";
import index from "../components/NavBarCompon";
import axios from "axios";
import useIsLoggedIn from '../App';
import { LogInNav, Input, ButtonContainer, LoginForm, ListContainer,
Error, Title, InputContainer, LogInBox, Inputt } from "../modules/LogIn.module";


function LogIn() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();


  function handleUsernameChange(event) {
    setUsername(event.target.value);
  }

  function handlePasswordChange(event) {
    setPassword(event.target.value);
  }

  async function sendLoginData() {
    const data = {
      name: username,
      password: password
    };
    const response = await fetch("http://doortroll.duckdns.org:3001/login", {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=UTF-8"
      },
      body: JSON.stringify(data)
    })
  
    if(response.ok){
    const token = await response.json();
    console.log(token);
    
    localStorage.clear();
    localStorage.setItem("token", token);
    navigate("/PresentStatus");
    }
  }
  
    const [extendLogBar, setExtendLogBar] = useState (false)
  
  function handleKeyPressed(event){
    if (event.key == 'Enter'){
      event.preventDefault();
      sendLoginData();
    }
  }

  function handleOnClick(){
    sendLoginData();
  }

  return (
    <LogInBox>
      
      <LoginForm>
     <InputContainer>
     <h1>Login</h1>
        <label htmlFor="username">Username:</label>
        <input
        type="text"
        id="username"
        value={username}
        onChange={handleUsernameChange}
        />
        </InputContainer>
        <br />
        <InputContainer>
        <label htmlFor="password">Password:</label>
        <input
          type="password"
          id="password"
          name="password"
          value={password}
          onChange={handlePasswordChange}
          onKeyDown={handleKeyPressed}
        /> </InputContainer>
        <br/>
        <div style={{ display: "flex", justifyContent: "center" }}>
      <ButtonContainer type="text"  onClick={handleOnClick}>Login </ButtonContainer>
      </div>
      </LoginForm>
        <br />
        
       </LogInBox>

  );
}

export default LogIn;

