import styled from "styled-components";
//Login.js styling module

  export const LogInBox = styled.div `/* centering login window*/
  
  display:flex;
  justify-items: center;
  align-items: center;
  width: 100%;
  
  flex-direction: column;
  `;

export const LogInNav = styled.div`/* not used at the moment*/
    font-family: sans-serif;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 1.5px;
    height: 35vh;
    width: 30vw;
    font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
    background-color: #f8f9fd;
    position: relative;
`;

  export const LoginForm = styled.div `
    background-color: white;
    padding: 1rem;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    width: 20vw;
    min-width: 220px;
    display:flex;
    justify-content: center;
    flex-direction: column;
    margin-top: 8rem;
    border-radius: 2rem;

    @media (max-width: 700px) {
    flex-direction: column;
    margin-top:15rem;
    
  }
  `;

    export const InputContainer = styled.div `
    display: flex;
    flex-direction: column;
    gap: 2px;
    margin: 10px;
    font-weight: bold;
  `;
  export const Input = styled.div`
  input[type="text"],
  input[type="password"] {
    height: 25px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    box-sizing: border-box;
    width: 100%;
  
    
    @media (max-width: 700px) {
    
    }
  } 
  
  input[type="submit"] {
    margin-top: 10px;
    cursor: pointer;
    font-size: 15px;
    background: #01d28e;
    border: 1px solid #01d28e;
    color: #fff;
    padding: 10px 20px;
    width: 100%;

    @media (max-width: 700px) {
    width: 0,5rem;
  }
  }
  
  input[type="submit"]:hover {
    background: #6cf0c2;
    width: 100%;
   
  } `;

 
  
  export const ButtonContainer = styled.button `
  
    display: flex;
    justify-content: center;
    font-size: 1rem;
    border: 2px black;
    border-radius: 5px;
    cursor: pointer;
    background-color: black;
    color: white;
    &:hover {
    background-color: #6082B6;}
    font-family: 'Cormorant Garamond', serif;
    
    min-width: 50px;
    max-width: 100px;
    padding: 0.2rem;
     
  `;