import styled from "styled-components";


export const RecOutBox = styled.div`
max-width: 680px;
width: 100%;
height: 70%

display: flex;
align-items: center;
margin-top: 30px;
margin-left: 5px;
margin-right: 5px;
justify-content: center;
flex-direction: column;
background: #f8f9fd;
border-radius: 20px;
box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
`

;

export const BarChartBox = styled.div`
 background: rgba(62, 68, 55, 0.1) 


`;

export const BarCh = styled.div`

margin-top: 5rem;
padding-bottom: 1rem;
padding-left: 1rem;
padding-right: 1rem;
margin-left: 1rem;


`;