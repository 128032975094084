import {  Link } from "react-router-dom";
import styled from "styled-components";


export const NavbarContainer = styled.nav`
display: flex;
justify-content: flex-start;
width: 100%;
height: ${(props) => (props.extendNavbar ? "100vh" : "180px")}

display: flex;
flex-direction: column;

@media (min-width: 700px){
	height: 180px;
}
`
;

export const LeftContainer = styled.nav`
flex: 40%;
display: flex;
align-items: center;
margin-top: 20px;
height: 180px;
justify-content: flex-end;
`;

export const RightContainer = styled.nav`
flex: 40%;
display: flex;
align-items: center;
margin-top: 20px;
height: 180px;
justify-content: flex-start;


`;

export const CenterContainer = styled.nav`
flex: 20%;
display: flex;
align-items: center;
margin-top: 20px;
height: 180px;
justify-content: space-evenly;



`;

export const NavbarExtendedContainer = styled.div`
display: flex;
flex-direction: row-reverse;
position: absolute;
flex-wrap: wrap;
align-content: flex-start;
flex-direction: column;
margin-top: 150px;
background-color: transparent;



@media (min-width: 700px) {
	display:none;
	
}
`;

export const NavbarInnerContainer = styled.div`
width: 100%;
height: 80px;
display: flex;
`;

export const NavBarLinkContainer = styled.div`
display:flex;

`;

export const NavbarLink = styled(Link)`
color: black;
font-size: 50px;
font-family: 'Cormorant Garamond', serif;
text-decoration: none;
margin: 10px;

@media (max-width: 700px){
	display: none;
}
`;

export const NavbarLinkExtend = styled(Link)`
color: black;
background-color: white;
border-radius: 6px;
border-color: #4e75a4;
box-Shadow: 0px 4px 8px rgba(0, 0, 0, 0.4);
font-size: x-large;
font-weight: bold;
font-family: 'Cormorant Garamond', serif;
text-decoration: none;
margin-left: 4px;
margin-top: 8px;
margin-bottom: 2px;


`;



export const Logo = styled.img.attrs(({ src }) => ({
	src: src,
	

	
	
  }))`
	
	margin-top: 40px;
max-width: 270px;
height: auto;`

export const OpenLinksButton = styled.button`
width: 60px;
height: 55px;
border-radius: 10px;
border-color: transparent;
box-Shadow: 0px 4px 8px rgba(0, 0, 0, 0.4);
background-color: white;
align-items: center;
color: #4e75a4;
font-size: 40px;
font-weight: bold;
cursor: pointer;
margin-right: 35px;
margin-left: 35px;

@media (min-width: 700px){
	display: none;
}
`;

export const LogoutButton = styled.button`
    display: flex;
    justify-content: center;
    font-size: 1rem;
    border: 2px black;
    border-radius: 5px;
    cursor: pointer;
    background-color: black;
    color: white;
    &:hover {
    background-color: #6082B6;}
    font-family: 'Cormorant Garamond', serif;
    
    width: 10vw;
    margin-left: 5%;
    padding: 0.2rem;
    @media (max-width: 700px){
      display: none;
    }
`;

export const LogoutButtonHide = styled.button`
    display: none;
`;