import { BarCh, RecOutBox } from "../modules/Recorded.module";
import React, { Component, useEffect, useState } from 'react'  
import { Bar, Chart } from 'react-chartjs-2';  
import {
  Chart as ChartJS,

  BarElement,

} from 'chart.js/auto';
import { json } from "react-router-dom";


ChartJS.register(
  BarElement,
);



//get sensor data from database and create a bar chart
const Recorded = () => {


	  const [sensor1data, setSensor1data] = useState([]);
	  const [startDate, setStartDate] = useState(new Date());
	  const [endDate, setEndDate] = useState(new Date());
	  const [moves, setMoves] = useState(new Date());
	
	
	useEffect(() =>{
		const fetchSensor = () => {
		  fetch("http://doortroll.duckdns.org:3001/people")
		  .then(response => response.json())
		  .then(json => {
			
			setSensor1data(json)
		  })
		  .catch(error => {
			console.log(error)
		  })
		}
		setInterval(fetchSensor, 1000)
	  },[])
	
	
	 
	  
	  var data = {
		labels: sensor1data.map(row => row.Date), //set label to be the date
		datasets: [{
		  label: `People`,
		  data:  sensor1data.map(row => row.moveCount), //set movecount for data
		  backgroundColor: [
			'#355070',
			"#2d445f",
			"#3d5c81",
			"#4e75a4"
	
		   
		  ],
		  borderColor: [
			'#355070',
			"#2d445f",
			"#3d5c81",
			"#4e75a4",
		   
		  ],
		  borderWidth: 1
		}]
	  };
	
	  var options = {
		maintainAspectRatio: false,
		scales: {
	
		},
		legend: {
		  labels: {
			fontSize: 25,
		  },
		},
	  
	  }
	



	return (
	    <div
      	style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: '170px',
		marginLeft: '30px',
		marginRight: '30px',
		marginBottom: '20px',
      }}
    >

	<RecOutBox>
		<BarCh>
		  <Bar
			data={data}
			height={400}
			options={options}
			
	
		  />
		  </BarCh>
		
	</RecOutBox>
	</div>
	
);
};


export default Recorded;