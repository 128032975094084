import React, { useEffect } from 'react';
import './App.css';
import Navbar from './components/NavBarCompon';
import { HashRouter as Router, Routes, Route} from 'react-router-dom';
import PresentStat from './pages/PresentStat';
import Recorded from './pages/Recorded';
import LogIn from './pages/LogIn';
import { useState } from 'react';
import { LogInNav, Input, ButtonContainer, LoginForm, ListContainer, Error, Title, InputContainer} from "./modules/LogIn.module"
import Protected from './pages/Protected';

function App() {

	 const [isLoggedIn, setIsLoggedIn] = useState(false);


	/* App.get('/sensor2', (req, res) => {
		const sql = 'SELECT Date, Timestamp, Movecount FROM sensor2';
		db.query(sql, (err, result) => {
			if (err) throw err;
			res.send(result);
		});
	});

	
	App.get('/sensor1', (req, res) => {
		const sql = 'SELECT Date, Timestamp, Movecount FROM sensor1';
		db.query(sql, (err, result) => {
			if (err) throw err;
			res.send(result);
		});
	});*/
	

return (
<Router>
	<Navbar/>
	{}
		<Routes>

		<Route exact path='/' element={<LogIn />}/>
		<Route path='/PresentStatus' element={<Protected isLoggedIn={isLoggedIn}><PresentStat/></Protected>}/>
		<Route path='/Recorded' element={<Protected isLoggedIn={isLoggedIn}><Recorded/></Protected>}/>
		
		</Routes>
</Router>


);
}

export default App;
