import React from 'react';
import { useEffect, useState } from "react";
import isLoggedIn from '../App';


const PresentStatus = () => {
  const [showFirstImage, setShowFirstImage] = useState(true);
  const [visitorValue, setVisitorValue] = useState(0);
  const [acStatus, setAcStatus] = useState(0);
  const [tempValue, setTempValue] = useState(0);
  const [imageUrl, setImageUrl] = useState("");
  const [humidityValue, setHumidityValue] = useState(0);
  const [airpressureValue, setAirpressureValue] = useState(0);
  const [co2Value, setCo2Value] = useState(0);
  const [tvocValue, setTvocValue] = useState(0);
  const [showOrHide, setShowOrHide] = useState(false);

  const handleToggleShowOrHide = () => {
    setShowOrHide(!showOrHide);
  };

  useEffect(() =>{
    const fetchVisitors = () => {
      fetch("http://doortroll.duckdns.org:3001/visitors")
      .then(response => response.text())
      .then(data => {
        const parsedData = parseInt(data)
        setVisitorValue(parsedData)
      })
      .catch(error => {
        console.log(error)
      })
    }
    setInterval(fetchVisitors, 1000)
  })

  useEffect(() => {
    if (visitorValue > 0) {
      setShowFirstImage(false);
    } else {
      setShowFirstImage(true);
    }
  }, [visitorValue]);

  useEffect(() =>{
    const fetchTemp = () => {
      fetch("http://doortroll.duckdns.org:3001/temperature")
      .then(response => response.text())
      .then(data => {
        const parsedData = parseFloat(data)
        setTempValue(parsedData)
      })
      .catch(error => {
        console.log(error)
      })
    }
    setInterval(fetchTemp, 1000)
  })

  useEffect(() =>{
    const fetchHumidity = () => {
      fetch("http://doortroll.duckdns.org:3001/humidity")
      .then(response => response.text())
      .then(data => {
        const parsedData = parseFloat(data)
        setHumidityValue(parsedData)
      })
      .catch(error => {
        console.log(error)
      })
    }
    setInterval(fetchHumidity, 1000)
  })

  useEffect(() =>{
    const fetchAirpressure = () => {
      fetch("http://doortroll.duckdns.org:3001/airpressure")
      .then(response => response.text())
      .then(data => {
        const parsedData = parseFloat(data)
        setAirpressureValue(parsedData)
      })
      .catch(error => {
        console.log(error)
      })
    }
    setInterval(fetchAirpressure, 1000)
  })

  useEffect(() =>{
    const fetchCo2 = () => {
      fetch("http://doortroll.duckdns.org:3001/co2")
      .then(response => response.text())
      .then(data => {
        const parsedData = parseInt(data)
        setCo2Value(parsedData)
      })
      .catch(error => {
        console.log(error)
      })
    }
    setInterval(fetchCo2, 1000)
  })

  useEffect(() =>{
    const fetchTvoc = () => {
      fetch("http://doortroll.duckdns.org:3001/tvoc")
      .then(response => response.text())
      .then(data => {
        const parsedData = parseInt(data)
        setTvocValue(parsedData)
      })
      .catch(error => {
        console.log(error)
      })
    }
    setInterval(fetchTvoc, 1000)
  })

  useEffect(() => {
    if (tempValue > -21 && tempValue < 20 && visitorValue > 0) {
      setAcStatus(1);
    } else if (tempValue > 24 && tempValue < 41 && visitorValue > 0) {
      setAcStatus(2);
    } else if ((tempValue > 40 || tempValue < -20) && visitorValue > 0) {
      setAcStatus(3);
    } else {
      setAcStatus(0);
    }
  }, [tempValue]);
  

  useEffect(() => {
    if (acStatus === 1) {
       setImageUrl ("/img/ac_on_heat.png");
    } else if (acStatus === 2) {
       setImageUrl ("/img/ac_on_cool.png");
    } else if (acStatus === 3) {
       setImageUrl ("/img/toohotorcold.gif");
    } else {
       setImageUrl ("/img/ac_off.png");
    }
  }, [acStatus]);

  const visitorValueAdd = () => {
    setVisitorValue(visitorValue + 1);
  };
  const visitorValueSubtract = () => {
	setVisitorValue(visitorValue - 1);
  };
  const tempValueAdd = () => {
    setTempValue(tempValue + 1);
  };
  const tempValueSubtract = () => {
	  setTempValue(tempValue - 1);
  };



  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        marginTop: '170px',
      }}
    >
      <div
        style={{
          display: 'flex',
          flex:'1',
          flexWrap:'wrap',
          maxWidth: '690px',
          alignItems: 'center',
          justifyContent: 'center',
          margin: '20px',
        }}
      >
        <div
          style={{
            width: '150px',
            height: '150px',
            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.4)',
            backgroundColor: '#fff',
            borderRadius: '20px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: '24px',
            fontWeight: 'bold',
            margin: '10px 10px',
          }}
        >
          {visitorValue}
          <br></br>
          Visitors
        </div>
        <div
          style={{
            width: '150px',
            height: '150px',
            border: '1px solid #ccc',
            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.4)',
            backgroundColor: '#fff',
            borderRadius: '20px',
            margin: '10px 10px',
          }}
        >
          {showFirstImage ? (
            <img
              src="/img/valot_off.png"
              alt="Valot Off"
              style={{ marginTop: '10px', borderRadius: '10px', width: '100px', height: '124px' }}
            />
          ) : (
            <img
              src="/img/valot_on.png"
              alt="Valot On"
              style={{ marginTop: '10px', borderRadius: '10px', width: '100px', height: '124px' }}
            />
          )}
        </div>

        <div
          style={{
            width: '150px',
            height: '150px',
            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.4)',
            backgroundColor: '#fff',
            borderRadius: '20px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: '24px',
            fontWeight: 'bold',
            margin: '10px 10px',
          }}
        >
          {tempValue}&deg;C
          <br></br>
          Temp
        </div>
        <div
          style={{
            width: '150px',
            height: '150px',
            border: '1px solid #ccc',
            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.4)',
            backgroundColor: '#fff',
            borderRadius: '20px',
            margin: '10px 10px',
          }}
        >
          
            <img
              src={imageUrl}
              alt="A/C"
              style={{ marginTop: '10px', borderRadius: '10px', width: '100px', height: '124px' }}
            />
          
        </div>

        {showOrHide && (

        <div
          style={{
            width: '150px',
            height: '150px',
            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.4)',
            backgroundColor: '#fff',
            borderRadius: '20px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: '24px',
            fontWeight: 'bold',
            margin: '10px 10px',
          }}
        >
          {humidityValue}%
          <br></br>
          Humidity
        </div>
        )}

        {showOrHide && (

        <div
          style={{
            width: '150px',
            height: '150px',
            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.4)',
            backgroundColor: '#fff',
            borderRadius: '20px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: '24px',
            fontWeight: 'bold',
            margin: '10px 10px',
          }}
        >
          {airpressureValue}hPa
          <br></br>
          Airpressure
        </div>
        )}

        {showOrHide && (

        <div
          style={{
            width: '150px',
            height: '150px',
            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.4)',
            backgroundColor: '#fff',
            borderRadius: '20px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: '24px',
            fontWeight: 'bold',
            margin: '10px 10px',
          }}
        >
          {co2Value}ppm
          <br></br>
          Co2
        </div>
        )}

        {showOrHide && (

        <div
          style={{
            width: '150px',
            height: '150px',
            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.4)',
            backgroundColor: '#fff',
            borderRadius: '20px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: '24px',
            fontWeight: 'bold',
            margin: '10px 10px',
          }}
        >
          {tvocValue}ppb
          <br></br>
          Tvoc
        </div>
        )}

      </div>
      
      <div>
        <br></br>
      <button onClick={handleToggleShowOrHide} style={{margin: '3px', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.4)', borderRadius: '5px', borderColor: 'white', backgroundColor: 'white',}}>
        {showOrHide ? 'Show less' : 'Show more'}
      </button>
      </div>

      {/*<div 
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
      }}
      >
      <button onClick={visitorValueAdd} style={{margin: '3px',}}>Sisään</button>
	    <button onClick={visitorValueSubtract} style={{margin: '3px',}}>Ulos</button>
      <button onClick={tempValueAdd} style={{margin: '3px',}}>Lämmintä</button>
	    <button onClick={tempValueSubtract} style={{margin: '3px',}}>Kylymää</button>
      </div>*/}
    </div>
  );
};

export default PresentStatus;