import React from "react";
import {  
	NavbarContainer,LeftContainer,RightContainer, 
	NavbarInnerContainer, NavbarExtendedContainer,
CenterContainer, NavBarLinkContainer, NavbarLink, Logo,
 OpenLinksButton, NavbarLinkExtend, LogoutButton, LogoutButtonHide }
	from "../modules/NavbarElements";
import LogoImage from "../pictures/testpic.png"
import { useState } from "react";
import { LoginForm } from "../modules/LogIn.module";
import { Navigate, useNavigate } from "react-router-dom";
import Protected from "../pages/Protected";

function Navbar () {
	const [extendNavbar, setExtendNavbar] = useState (false)
	const navigate = useNavigate();

	function handleLogout() {
		localStorage.clear();
		navigate("/");
	}
	
	let userToken = localStorage.getItem("token");
	let nappiInnerds;
	let userIsLoggedIn;

		if(!userToken || userToken === 'undefined'){
			userIsLoggedIn=false;
			nappiInnerds = (
			  <LogoutButton style={{ display: "none" }}>
			  no log out button here
			  </LogoutButton>
			);
		  } else {
			userIsLoggedIn=true;
			nappiInnerds = (
			  <LogoutButton type="button" onClick={handleLogout}>
			  Log Out
			  </LogoutButton>
			);
		  }

return (

	

	<NavbarContainer extendNavbar= {extendNavbar}>
		
		
		<NavbarInnerContainer>
		<LeftContainer>
			 
			<NavBarLinkContainer>
			<NavbarLink to="/Recorded"> Recorded 
				</NavbarLink>
				{userIsLoggedIn ? (
            	<OpenLinksButton onClick={() => setExtendNavbar((curr) => !curr)}>
            	{extendNavbar ? <> &#10005; </> : <> &#8801;</>}
            	</OpenLinksButton>
          		) : (
            	<OpenLinksButton>{extendNavbar ? <> &#10005; </> : <> &#8801;</>}</OpenLinksButton>
          		)}
			</NavBarLinkContainer>
		</LeftContainer>
		<CenterContainer>
		<Logo src={LogoImage} />

		</CenterContainer>
		<RightContainer>
		<NavBarLinkContainer>
				
				<NavbarLink to="/PresentStatus"> PresentStat 
				</NavbarLink>
			</NavBarLinkContainer>
			{nappiInnerds}
		</RightContainer>
		</NavbarInnerContainer>
		{extendNavbar &&
			<NavbarExtendedContainer>
		<NavbarLinkExtend onClick={() => setExtendNavbar(!extendNavbar)} to="/Recorded"> Recorded 
				</NavbarLinkExtend>
		<NavbarLinkExtend onClick={() => setExtendNavbar(!extendNavbar)} to="/PresentStatus"> PresentStat 
				</NavbarLinkExtend>
		<NavbarLinkExtend onClick={() => {handleLogout(); setExtendNavbar(!extendNavbar);}} to="./">Log out
				</NavbarLinkExtend>
		</NavbarExtendedContainer>
}
	</NavbarContainer>

	
);
};

export default Navbar;
